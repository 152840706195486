<template>
  <section>
    <div class="workship-relation" v-show="pageDetailHide" v-if="refreshStatus">
      <edp-header
        class="header-bg"
        :headerLeftWord="$t('workshipRelation.workRelationshipGraph')"
      ></edp-header>
      <div class="topTips fac">
        <img src="@/src/assets/member/notice.png" alt="" class="tip" />
        <span class="f14 op5"> {{ $t("workshipRelation.Remark") }}</span>
      </div>
      <div
        :class="[
          isPc
            ? `workship-relation-content-isPc`
            : isLargePc
            ? `workship-relation-content-isLargePc`
            : ``,
          `edp-common-content`,
          `workship-relation-content`,
        ]"
      >
        <div class="workship-relation-title">
          {{ $t("workshipRelation.workRelationshipTitle") }}
        </div>
        <div class="workship-relation-titleen">
          {{
            lang === "cn" ? "" : $t("workshipRelation.workRelationshipTitle1")
          }}
        </div>
        <div>
          <div
            :class="
              isPc
                ? `workship-relation-graph-isPc`
                : isLargePc
                ? `workship-relation-graph-isLargePc`
                : `workship-relation-graph`
            "
            :style="{
              backgroundImage: `url(${empRelationMap?.emp?.photo ?? nullImg})`,
              backgroundSize: 'cover',
            }"
          ></div>
          <div
            :class="
              isPc
                ? `workship-relation-alumni-wrap-isPc`
                : isLargePc
                ? `workship-relation-alumni-wrap-isLargePc`
                : `workship-relation-alumni-wrap`
            "
          >
            <svg
              class="workship-relation-line"
              width="17"
              height="268"
              viewBox="0 0 17 268"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="15.8862"
                y1="266.479"
                x2="1.05783"
                y2="1.05424"
                stroke="black"
                stroke-opacity="0.4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-dasharray="8 8"
              />
            </svg>
            <div
              :style="
                empRelationMap?.empAlumni?.length == 0
                  ? `background: #D9D9D9;`
                  : ``
              "
              :class="
                isPc
                  ? lang === 'cn'
                    ? `workship-relation-alumni-elliptic-isPc`
                    : `workship-relation-alumni-elliptic-en-isPc`
                  : isLargePc
                  ? lang === 'cn'
                    ? `workship-relation-alumni-elliptic-isLargePc`
                    : `workship-relation-alumni-elliptic-en-isLargePc`
                  : lang === 'cn'
                  ? `workship-relation-alumni-elliptic`
                  : `workship-relation-alumni-elliptic-en`
              "
            >
              <div
                :class="
                  isPc
                    ? lang === 'cn'
                      ? `workship-relation-alumni-text-isPc`
                      : `workship-relation-alumni-text-en-isPc`
                    : isLargePc
                    ? lang === 'cn'
                      ? `workship-relation-alumni-text-isLargePc`
                      : `workship-relation-alumni-text-en-isLargePc`
                    : lang === 'cn'
                    ? `workship-relation-alumni-text`
                    : `workship-relation-alumni-text-en`
                "
              >
                {{ $t("workshipRelation.Alumni") }}
              </div>
            </div>
          </div>
          <div
            :class="
              isPc
                ? `workship-relation-alumni-isPc`
                : isLargePc
                ? `workship-relation-alumni-isLargePc`
                : `workship-relation-alumni`
            "
            @click.stop="onNodeClick(1)"
            :style="{
              backgroundImage: `url(${alumniFirstPhoto})`,
              backgroundSize: 'cover',
              cursor: empRelationMap?.empAlumni?.length > 0 ? `pointer` : '',
            }"
          >
            <div
              v-if="
                empRelationMap?.empAlumni?.length > 0 &&
                empRelationMap?.empAlumni[0]?.isEmpower == 0
              "
              @click.stop="onNodeClick(1)"
              class="workship-relation-alumni-noPower"
              :style="
                empRelationMap?.empAlumni?.length > 1 ? `cursor: pointer` : ''
              "
            >
              <div
                :class="
                  isPc
                    ? `workship-relation-noPower-text-isPc`
                    : isLargePc
                    ? `workship-relation-noPower-text-isLargePc`
                    : `workship-relation-noPower-text`
                "
              >
                <p
                  v-html="
                    $t(
                      isPc
                        ? `workshipRelation.noPowerCirclePc`
                        : `workshipRelation.noPowerCircle`
                    )
                  "
                ></p>
              </div>
            </div>
          </div>
          <div
            v-if="empRelationMap?.empAlumni?.length > 1"
            @click.stop="onNodeClick(1)"
            :class="
              isPc
                ? `workship-relation-alumni-photos-isPc`
                : isLargePc
                ? `workship-relation-alumni-photos-isLargePc`
                : `workship-relation-alumni-photos`
            "
            :style="
              empRelationMap?.empAlumni?.length > 1 ? `cursor: pointer` : ''
            "
          >
            <div
              v-for="(item, i) in empRelationMap?.empAlumni?.slice(
                1,
                empRelationMap?.empAlumni?.length >= 5
                  ? 5
                  : empRelationMap?.empAlumni?.length
              )"
              :key="i"
              :class="
                i == 0
                  ? `workship-relation-photos1`
                  : `workship-relation-photosn`
              "
              :style="{
                backgroundImage: `url(${
                  empRelationMap?.empAlumni[i]?.isEmpower == 1
                    ? empRelationMap?.empAlumni[i]?.photo ?? nullImg
                    : nullImg
                })`,
                backgroundSize: 'cover',
              }"
            ></div>
            <div
              v-if="empRelationMap?.empAlumni?.length > 5"
              class="workship-relation-number"
            >
              <div class="workship-relation-amount">
                +{{ empRelationMap?.empAlumni?.length - 1 }}
              </div>
            </div>
          </div>
          <div
            v-if="empRelationMap?.empAlumni?.length > 0"
            :style="
              empRelationMap?.empAlumni[0]?.isEmpower == 0
                ? `filter:blur(3px);`
                : ``
            "
            :class="
              isPc
                ? `workship-relation-alumni-content-isPc`
                : isLargePc
                ? `workship-relation-alumni-content-isLargePc`
                : `workship-relation-alumni-content`
            "
          >
            <div
              :class="
                isPc
                  ? `workship-relation-name-isPc`
                  : isLargePc
                  ? `workship-relation-name-isLargePc`
                  : `workship-relation-name`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empAlumni[0]?.cname ||
                    empRelationMap?.empAlumni[0]?.ename
                  : empRelationMap?.empAlumni[0]?.ename ||
                    empRelationMap?.empAlumni[0]?.cname
              }}
            </div>
            <div
              :class="
                isPc
                  ? `workship-relation-position-isPc`
                  : isLargePc
                  ? `workship-relation-position-isLargePc`
                  : `workship-relation-position`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empAlumni[0]?.dept ||
                    empRelationMap?.empAlumni[0]?.deptEname
                  : empRelationMap?.empAlumni[0]?.deptEname ||
                    empRelationMap?.empAlumni[0]?.dept
              }}
            </div>
          </div>
          <div
            :class="
              isPc
                ? `workship-relation-leader-wrap-isPc`
                : isLargePc
                ? `workship-relation-leader-wrap-isLargePc`
                : `workship-relation-leader-wrap`
            "
          >
            <svg
              class="workship-relation-line"
              width="307"
              height="132"
              viewBox="0 0 307 132"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="305.628"
                y1="130.95"
                x2="1.52825"
                y2="1.31206"
                stroke="black"
                stroke-opacity="0.4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-dasharray="8 8"
              />
            </svg>
            <div
              :style="
                empRelationMap?.empLeader == null ? `background: #D9D9D9;` : ``
              "
              :class="
                isPc
                  ? lang === 'cn'
                    ? `workship-relation-leader-elliptic-isPc`
                    : `workship-relation-leader-elliptic-en-isPc`
                  : isLargePc
                  ? lang === 'cn'
                    ? `workship-relation-leader-elliptic-isLargePc`
                    : `workship-relation-leader-elliptic-en-isLargePc`
                  : lang === 'cn'
                  ? `workship-relation-leader-elliptic`
                  : `workship-relation-leader-elliptic-en`
              "
            >
              <div
                :class="
                  isPc
                    ? lang === 'cn'
                      ? `workship-relation-leader-text-isPc`
                      : `workship-relation-leader-text-en-isPc`
                    : isLargePc
                    ? lang === 'cn'
                      ? `workship-relation-leader-text-isLargePc`
                      : `workship-relation-leader-text-en-isLargePc`
                    : lang === 'cn'
                    ? `workship-relation-leader-text`
                    : `workship-relation-leader-text-en`
                "
              >
                {{ $t("workshipRelation.Leader") }}
              </div>
            </div>
          </div>
          <div
            @click.stop="onNodeClick(2)"
            :class="[
              isPc
                ? `workship-relation-leader-isPc`
                : isLargePc
                ? `workship-relation-leader-isLargePc`
                : `workship-relation-leader`,
            ]"
            :style="{
              backgroundImage: `url(${
                empRelationMap?.empLeader?.photo ?? nullImg
              })`,
              backgroundSize: 'cover',
              cursor:
                empRelationMap?.empLeader?.isEmpower == 1 ? `pointer` : '',
            }"
          >
            <div
              v-if="empRelationMap?.empLeader?.isEmpower == 0"
              :class="
                isPc
                  ? `workship-relation-leader-noPower-isPc`
                  : isLargePc
                  ? `workship-relation-leader-noPower-isLargePc`
                  : `workship-relation-leader-noPower`
              "
            >
              <div
                :class="
                  isPc
                    ? `workship-relation-noPower-text-isPc`
                    : isLargePc
                    ? `workship-relation-noPower-text-isLargePc`
                    : `workship-relation-noPower-text`
                "
              >
                <p
                  v-html="
                    $t(
                      isPc
                        ? `workshipRelation.noPowerCirclePc`
                        : `workshipRelation.noPowerCircle`
                    )
                  "
                ></p>
              </div>
            </div>
          </div>
          <div
            :style="
              empRelationMap?.empLeader?.isEmpower == 0
                ? `filter:blur(3px);`
                : ``
            "
            :class="
              isPc
                ? `workship-relation-leader-content-isPc`
                : isLargePc
                ? `workship-relation-leader-content-isLargePc`
                : `workship-relation-leader-content`
            "
          >
            <div
              :class="
                isPc
                  ? `workship-relation-name-isPc`
                  : isLargePc
                  ? `workship-relation-name-isLargePc`
                  : `workship-relation-name`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empLeader?.cname ||
                    empRelationMap?.empLeader?.ename
                  : empRelationMap?.empLeader?.ename ||
                    empRelationMap?.empLeader?.cname
              }}
            </div>
            <div
              :class="
                isPc
                  ? `workship-relation-position-isPc`
                  : isLargePc
                  ? `workship-relation-position-isLargePc`
                  : `workship-relation-position`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empLeader?.dept ||
                    empRelationMap?.empLeader?.deptEname
                  : empRelationMap?.empLeader?.deptEname ||
                    empRelationMap?.empLeader?.dept
              }}
            </div>
          </div>
          <div
            :class="
              isPc
                ? `workship-relation-subordinates-wrap-isPc`
                : isLargePc
                ? `workship-relation-subordinates-wrap-isLargePc`
                : `workship-relation-subordinates-wrap`
            "
          >
            <svg
              class="workship-relation-line"
              width="264"
              height="214"
              viewBox="0 0 264 214"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="262.85"
                y1="1.40613"
                x2="1.40662"
                y2="212.151"
                stroke="black"
                stroke-opacity="0.4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-dasharray="8 8"
              />
            </svg>
            <div
              :style="
                empRelationMap?.empSubordinates?.length == 0
                  ? `background: #D9D9D9;`
                  : ``
              "
              :class="
                isPc
                  ? lang === 'cn'
                    ? `workship-relation-subordinates-elliptic-isPc`
                    : `workship-relation-subordinates-elliptic-en-isPc`
                  : isLargePc
                  ? lang === 'cn'
                    ? `workship-relation-subordinates-elliptic-isLargePc`
                    : `workship-relation-subordinates-elliptic-en-isLargePc`
                  : lang === 'cn'
                  ? `workship-relation-subordinates-elliptic`
                  : `workship-relation-subordinates-elliptic-en`
              "
            >
              <div
                :class="
                  isPc
                    ? lang === 'cn'
                      ? `workship-relation-subordinates-text-isPc`
                      : `workship-relation-subordinates-text-en-isPc`
                    : isLargePc
                    ? lang === 'cn'
                      ? `workship-relation-subordinates-text-isLargePc`
                      : `workship-relation-subordinates-text-en-isLargePc`
                    : lang === 'cn'
                    ? `workship-relation-subordinates-text`
                    : `workship-relation-subordinates-text-en`
                "
              >
                {{ $t("workshipRelation.Directsubordinate") }}
              </div>
            </div>
          </div>
          <div
            @click.stop="onNodeClick(3)"
            :class="
              isPc
                ? `workship-relation-subordinates-isPc`
                : isLargePc
                ? `workship-relation-subordinates-isLargePc`
                : `workship-relation-subordinates`
            "
            :style="{
              backgroundImage: `url(${subordinateFirstPhoto})`,
              backgroundSize: 'cover',
              cursor:
                empRelationMap?.empSubordinates?.length > 0 ? `pointer` : '',
            }"
          >
            <div
              v-if="
                empRelationMap?.empSubordinates?.length > 0 &&
                empRelationMap?.empSubordinates[0]?.isEmpower == 0
              "
              @click.stop="onNodeClick(3)"
              :class="
                isPc
                  ? `workship-relation-subordinates-noPower-isPc`
                  : isLargePc
                  ? `workship-relation-subordinates-noPower-isLargePc`
                  : `workship-relation-subordinates-noPower`
              "
              :style="
                empRelationMap?.empSubordinates?.length > 1
                  ? `cursor: pointer`
                  : ''
              "
            >
              <div
                :class="
                  isPc
                    ? `workship-relation-noPower-text-isPc`
                    : isLargePc
                    ? `workship-relation-noPower-text-isLargePc`
                    : `workship-relation-noPower-text`
                "
              >
                <p
                  v-html="
                    $t(
                      isPc
                        ? `workshipRelation.noPowerCirclePc`
                        : `workshipRelation.noPowerCircle`
                    )
                  "
                ></p>
              </div>
            </div>
          </div>
          <div
            v-if="empRelationMap?.empSubordinates?.length > 0"
            :style="
              empRelationMap?.empSubordinates[0]?.isEmpower == 0
                ? `filter:blur(3px);`
                : ``
            "
            :class="
              isPc
                ? `workship-relation-subordinates-content-isPc`
                : isLargePc
                ? `workship-relation-subordinates-content-isLargePc`
                : `workship-relation-subordinates-content`
            "
          >
            <div
              :class="
                isPc
                  ? `workship-relation-name-isPc`
                  : isLargePc
                  ? `workship-relation-name-isLargePc`
                  : `workship-relation-name`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empSubordinates[0]?.subordinateCname ||
                    empRelationMap?.empSubordinates[0]?.subordinateEname
                  : empRelationMap?.empSubordinates[0]?.subordinateEname ||
                    empRelationMap?.empSubordinates[0]?.subordinateCname
              }}
            </div>
            <div
              :class="
                isPc
                  ? `workship-relation-position-isPc`
                  : isLargePc
                  ? `workship-relation-position-isLargePc`
                  : `workship-relation-position`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empSubordinates[0]?.dept ||
                    empRelationMap?.empSubordinates[0]?.deptEname
                  : empRelationMap?.empSubordinates[0]?.deptEname ||
                    empRelationMap?.empSubordinates[0]?.dept
              }}
            </div>
          </div>
          <div
            v-if="empRelationMap?.empSubordinates?.length > 1"
            @click.stop="onNodeClick(3)"
            :class="
              isPc
                ? `workship-relation-subordinates-photos-isPc`
                : isLargePc
                ? `workship-relation-subordinates-photos-isLargePc`
                : `workship-relation-subordinates-photos`
            "
            :style="
              empRelationMap?.empSubordinates?.length > 1
                ? `cursor: pointer`
                : ''
            "
          >
            <div
              v-for="(item, i) in empRelationMap?.empSubordinates?.slice(
                1,
                empRelationMap?.empSubordinates?.length >= 5
                  ? 5
                  : empRelationMap?.empSubordinates?.length
              )"
              :key="i"
              :class="
                i == 0
                  ? `workship-relation-photos1`
                  : `workship-relation-photosn`
              "
              :style="{
                backgroundImage: `url(${
                  empRelationMap?.empSubordinates[i]?.isEmpower == 1
                    ? empRelationMap?.empSubordinates[i]?.photo ?? nullImg
                    : nullImg
                })`,
                backgroundSize: 'cover',
              }"
            ></div>
            <div
              v-if="empRelationMap?.empSubordinates?.length > 5"
              class="workship-relation-number"
            >
              <div class="workship-relation-amount">
                +{{ empRelationMap?.empSubordinates?.length - 1 }}
              </div>
            </div>
          </div>
          <div
            :class="
              isPc
                ? `workship-relation-peers-wrap-isPc`
                : isLargePc
                ? `workship-relation-peers-wrap-isLargePc`
                : `workship-relation-peers-wrap`
            "
          >
            <svg
              class="workship-relation-line"
              width="165"
              height="293"
              viewBox="0 0 165 293"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1.30131"
                y1="1.80462"
                x2="163.385"
                y2="291.639"
                stroke="black"
                stroke-opacity="0.4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-dasharray="8 8"
              />
            </svg>
            <div
              :style="
                empRelationMap?.empTeamPeer?.length == 0
                  ? `background: #D9D9D9;`
                  : ``
              "
              :class="
                isPc
                  ? lang === 'cn'
                    ? `workship-relation-peers-elliptic-isPc`
                    : `workship-relation-peers-elliptic-en-isPc`
                  : isLargePc
                  ? lang === 'cn'
                    ? `workship-relation-peers-elliptic-isLargePc`
                    : `workship-relation-peers-elliptic-en-isLargePc`
                  : lang === 'cn'
                  ? `workship-relation-peers-elliptic`
                  : `workship-relation-peers-elliptic-en`
              "
            >
              <div
                :class="
                  isPc
                    ? lang === 'cn'
                      ? `workship-relation-peers-text-isPc`
                      : `workship-relation-peers-text-en-isPc`
                    : isLargePc
                    ? lang === 'cn'
                      ? `workship-relation-peers-text-isLargePc`
                      : `workship-relation-peers-text-en-isLargePc`
                    : lang === 'cn'
                    ? `workship-relation-peers-text`
                    : `workship-relation-peers-text-en`
                "
              >
                {{ $t("workshipRelation.Peers") }}
              </div>
            </div>
          </div>
          <div
            :class="
              isPc
                ? `workship-relation-peers-isPc`
                : isLargePc
                ? `workship-relation-peers-isLargePc`
                : `workship-relation-peers`
            "
            @click.stop="onNodeClick(4)"
            :style="{
              backgroundImage: `url(${peersFirstPhoto})`,
              backgroundSize: 'cover',
              cursor: empRelationMap?.empTeamPeer?.length > 0 ? `pointer` : '',
            }"
          >
            <div
              v-if="
                empRelationMap?.empTeamPeer?.length > 0 &&
                empRelationMap?.empTeamPeer[0]?.isEmpower == 0
              "
              @click.stop="onNodeClick(4)"
              :class="
                isPc
                  ? `workship-relation-peers-noPower-isPc`
                  : isLargePc
                  ? `workship-relation-peers-noPower-isLargePc`
                  : `workship-relation-peers-noPower`
              "
              :style="
                empRelationMap?.empTeamPeer?.length > 1 ? `cursor: pointer` : ''
              "
            >
              <div
                :class="
                  isPc
                    ? `workship-relation-noPower-text-isPc`
                    : isLargePc
                    ? `workship-relation-noPower-text-isLargePc`
                    : `workship-relation-noPower-text`
                "
              >
                <p
                  v-html="
                    $t(
                      isPc
                        ? `workshipRelation.noPowerCirclePc`
                        : `workshipRelation.noPowerCircle`
                    )
                  "
                ></p>
              </div>
            </div>
          </div>
          <div
            v-if="empRelationMap?.empTeamPeer?.length > 1"
            @click.stop="onNodeClick(4)"
            :class="
              isPc
                ? `workship-relation-peers-photos-isPc`
                : isLargePc
                ? `workship-relation-peers-photos-isLargePc`
                : `workship-relation-peers-photos`
            "
            :style="
              empRelationMap?.empTeamPeer?.length > 1 ? `cursor: pointer` : ''
            "
          >
            <div
              v-for="(item, i) in empRelationMap?.empTeamPeer?.slice(
                1,
                empRelationMap?.empTeamPeer?.length >= 5
                  ? 5
                  : empRelationMap?.empTeamPeer?.length
              )"
              :key="i"
              :class="
                i == 0
                  ? `workship-relation-photos1`
                  : `workship-relation-photosn`
              "
              :style="{
                backgroundImage: `url(${
                  empRelationMap?.empTeamPeer[i]?.isEmpower == 1
                    ? empRelationMap?.empTeamPeer[i]?.photo ?? nullImg
                    : nullImg
                })`,
                backgroundSize: 'cover',
              }"
            ></div>
            <div
              v-if="empRelationMap?.empTeamPeer?.length > 5"
              class="workship-relation-number"
            >
              <div class="workship-relation-amount">
                +{{ empRelationMap?.empTeamPeer?.length - 1 }}
              </div>
            </div>
          </div>
          <div
            v-if="empRelationMap?.empTeamPeer?.length > 0"
            :class="
              isPc
                ? `workship-relation-peers-content-isPc`
                : isLargePc
                ? `workship-relation-peers-content-isLargePc`
                : `workship-relation-peers-content`
            "
            :style="
              empRelationMap?.empTeamPeer[0]?.isEmpower == 0
                ? `filter:blur(3px);`
                : ``
            "
          >
            <div
              :class="
                isPc
                  ? `workship-relation-name-isPc`
                  : isLargePc
                  ? `workship-relation-name-isLargePc`
                  : `workship-relation-name`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empTeamPeer[0]?.cname ||
                    empRelationMap?.empTeamPeer[0]?.ename
                  : empRelationMap?.empTeamPeer[0]?.ename ||
                    empRelationMap?.empTeamPeer[0]?.cname
              }}
            </div>
            <div
              :class="
                isPc
                  ? `workship-relation-position-isPc`
                  : isLargePc
                  ? `workship-relation-position-isLargePc`
                  : `workship-relation-position`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empTeamPeer[0]?.dept ||
                    empRelationMap?.empTeamPeer[0]?.deptEname
                  : empRelationMap?.empTeamPeer[0]?.deptEname ||
                    empRelationMap?.empTeamPeer[0]?.dept
              }}
            </div>
          </div>

          <!-- friend start -->

          <div
            :class="
              isPc
                ? `workship-relation-partners-wrap-isPc`
                : isLargePc
                ? `workship-relation-partners-wrap-isLargePc`
                : `workship-relation-partners-wrap`
            "
          >
            <svg
              class="workship-relation-line"
              width="358"
              height="121"
              viewBox="0 0 358 121"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1.20464"
                y1="1.78606"
                x2="356.367"
                y2="119.736"
                stroke="black"
                stroke-opacity="0.4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-dasharray="8 8"
              />
            </svg>
            <div
              :style="
                empRelationMap?.empFriends?.length == 0
                  ? `background: #D9D9D9;`
                  : ``
              "
              :class="
                isPc
                  ? lang === 'cn'
                    ? `workship-relation-partners-elliptic-isPc`
                    : `workship-relation-partners-elliptic-en-isPc`
                  : isLargePc
                  ? lang === 'cn'
                    ? `workship-relation-partners-elliptic-isLargePc`
                    : `workship-relation-partners-elliptic-en-isLargePc`
                  : lang === 'cn'
                  ? `workship-relation-partners-elliptic`
                  : `workship-relation-partners-elliptic-en`
              "
            >
              <div
                :class="
                  isPc
                    ? lang === 'cn'
                      ? `workship-relation-partners-text-isPc`
                      : `workship-relation-partners-text-en-isPc`
                    : isLargePc
                    ? lang === 'cn'
                      ? `workship-relation-partners-text-isLargePc`
                      : `workship-relation-partners-text-en-isLargePc`
                    : lang === 'cn'
                    ? `workship-relation-partners-text`
                    : `workship-relation-partners-text-en`
                "
              >
                {{ $t("workshipRelation.Friends") }}
              </div>
            </div>
          </div>
          <div
            :class="
              isPc
                ? `workship-relation-partners-isPc`
                : isLargePc
                ? `workship-relation-partners-isLargePc`
                : `workship-relation-partners`
            "
            @click.stop="onNodeClick(6)"
            :style="{
              backgroundImage: `url(${friendsFirstPhoto})`,
              backgroundSize: 'cover',
              cursor: empRelationMap?.empFriends?.length > 0 ? `pointer` : '',
            }"
          >
            <div
              v-if="
                empRelationMap?.empFriends?.length > 0 &&
                empRelationMap?.empFriends[0]?.isEmpower == 0
              "
              @click.stop="onNodeClick(6)"
              :class="
                isPc
                  ? `workship-relation-partners-noPower-isPc`
                  : isLargePc
                  ? `workship-relation-partners-noPower-isLargePc`
                  : `workship-relation-partners-noPower`
              "
              :style="
                empRelationMap?.empFriends?.length > 1 ? `cursor: pointer` : ''
              "
            >
              <div
                :class="
                  isPc
                    ? `workship-relation-noPower-text-isPc`
                    : isLargePc
                    ? `workship-relation-noPower-text-isLargePc`
                    : `workship-relation-noPower-text`
                "
              >
                <p
                  v-html="
                    $t(
                      isPc
                        ? `workshipRelation.noPowerCirclePc`
                        : `workshipRelation.noPowerCircle`
                    )
                  "
                ></p>
              </div>
            </div>
          </div>
          <div
            v-if="empRelationMap?.empFriends?.length > 1"
            @click.stop="onNodeClick(6)"
            :class="
              isPc
                ? `workship-relation-partners-photos-isPc`
                : isLargePc
                ? `workship-relation-partners-photos-isLargePc`
                : `workship-relation-partners-photos`
            "
            :style="
              empRelationMap?.empFriends?.length > 1 ? `cursor: pointer` : ''
            "
          >
            <div
              v-for="(item, i) in empRelationMap?.empFriends?.slice(
                1,
                empRelationMap?.empFriends?.length >= 5
                  ? 5
                  : empRelationMap?.empFriends?.length
              )"
              :key="i"
              :class="
                i == 0
                  ? `workship-relation-photos1`
                  : `workship-relation-photosn`
              "
              :style="{
                backgroundImage: `url(${
                  empRelationMap?.empFriends[i]?.isEmpower == 1
                    ? empRelationMap?.empFriends[i]?.photo ?? nullImg
                    : nullImg
                })`,
                backgroundSize: 'cover',
              }"
            ></div>
            <div
              v-if="empRelationMap?.empFriends?.length > 5"
              class="workship-relation-number"
            >
              <div class="workship-relation-amount">
                +{{ empRelationMap?.empFriends?.length - 1 }}
              </div>
            </div>
          </div>
          <div
            v-if="empRelationMap?.empFriends?.length > 0"
            :style="
              empRelationMap?.empFriends[0]?.isEmpower == 0
                ? `filter:blur(3px);`
                : ``
            "
            :class="
              isPc
                ? `workship-relation-partners-content-isPc`
                : isLargePc
                ? `workship-relation-partners-content-isLargePc`
                : `workship-relation-partners-content`
            "
          >
            <div
              :class="
                isPc
                  ? `workship-relation-name-isPc`
                  : isLargePc
                  ? `workship-relation-name-isLargePc`
                  : `workship-relation-name`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empCooperates[0]?.cname ||
                    empRelationMap?.empCooperates[0]?.ename
                  : empRelationMap?.empCooperates[0]?.ename ||
                    empRelationMap?.empCooperates[0]?.cname
              }}
            </div>
            <div
              :class="
                isPc
                  ? `workship-relation-position-isPc`
                  : isLargePc
                  ? `workship-relation-position-isLargePc`
                  : `workship-relation-position`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empCooperates[0]?.dept ||
                    empRelationMap?.empCooperates[0]?.deptEname
                  : empRelationMap?.empCooperates[0]?.deptEname ||
                    empRelationMap?.empCooperates[0]?.dept
              }}
            </div>
          </div>
          <!-- friend end -->

          <div
            :class="
              isPc
                ? `workship-relation-friends-wrap-isPc`
                : isLargePc
                ? `workship-relation-friends-wrap-isLargePc`
                : `workship-relation-friends-wrap`
            "
          >
            <svg
              class="workship-relation-line"
              width="376"
              height="234"
              viewBox="0 0 376 234"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1.32478"
                y1="232.623"
                x2="374.624"
                y2="1.67571"
                stroke="black"
                stroke-opacity="0.4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-dasharray="8 8"
              />
            </svg>
            <div
              :style="
                empRelationMap?.empCooperates?.length == 0
                  ? `background: #D9D9D9;`
                  : ``
              "
              :class="
                isPc
                  ? lang === 'cn'
                    ? `workship-relation-friends-elliptic-isPc`
                    : `workship-relation-friends-elliptic-en-isPc`
                  : isLargePc
                  ? lang === 'cn'
                    ? `workship-relation-friends-elliptic-isLargePc`
                    : `workship-relation-friends-elliptic-en-isLargePc`
                  : lang === 'cn'
                  ? `workship-relation-friends-elliptic`
                  : `workship-relation-friends-elliptic-en`
              "
            >
              <div
                :class="
                  isPc
                    ? lang === 'cn'
                      ? `workship-relation-friends-text-isPc`
                      : `workship-relation-friends-text-en-isPc`
                    : isLargePc
                    ? lang === 'cn'
                      ? `workship-relation-friends-text-isLargePc`
                      : `workship-relation-friends-text-en-isLargePc`
                    : lang === 'cn'
                    ? `workship-relation-friends-text`
                    : `workship-relation-friends-text-en`
                "
              >
                {{ $t("workshipRelation.PartnerTop20") }}
              </div>
            </div>
          </div>
          <div
            :class="
              isPc
                ? `workship-relation-friends-isPc`
                : isLargePc
                ? `workship-relation-friends-isLargePc`
                : `workship-relation-friends`
            "
            @click.stop="onNodeClick(5)"
            :style="{
              backgroundImage: `url(${cooperatesFirstPhoto})`,
              backgroundSize: 'cover',
              cursor:
                empRelationMap?.empCooperates?.length > 1 ? `pointer` : '',
            }"
          >
            <div
              v-if="
                empRelationMap?.empCooperates?.length > 0 &&
                empRelationMap?.empCooperates[0]?.isEmpower == 0
              "
              @click.stop="onNodeClick(5)"
              :class="
                isPc
                  ? `workship-relation-friends-noPower-isPc`
                  : isLargePc
                  ? `workship-relation-friends-noPower-isLargePc`
                  : `workship-relation-friends-noPower`
              "
              :style="
                empRelationMap?.empCooperates?.length > 1
                  ? `cursor: pointer`
                  : ''
              "
            >
              <div
                :class="
                  isPc
                    ? `workship-relation-noPower-text-isPc`
                    : isLargePc
                    ? `workship-relation-noPower-text-isLargePc`
                    : `workship-relation-noPower-text`
                "
              >
                <p
                  v-html="
                    $t(
                      isPc
                        ? `workshipRelation.noPowerCirclePc`
                        : `workshipRelation.noPowerCircle`
                    )
                  "
                ></p>
              </div>
            </div>
          </div>
          <div
            v-if="empRelationMap?.empCooperates?.length > 1"
            @click.stop="onNodeClick(5)"
            :class="
              isPc
                ? `workship-relation-friends-photos-isPc`
                : isLargePc
                ? `workship-relation-friends-photos-isLargePc`
                : `workship-relation-friends-photos`
            "
            :style="
              empRelationMap?.empCooperates?.length > 1 ? `cursor: pointer` : ''
            "
          >
            <div
              v-for="(item, i) in empRelationMap?.empCooperates?.slice(
                1,
                empRelationMap?.empCooperates?.length >= 5
                  ? 5
                  : empRelationMap?.empCooperates?.length
              )"
              :key="i"
              :class="
                i == 0
                  ? `workship-relation-photos1`
                  : `workship-relation-photosn`
              "
              :style="{
                backgroundImage: `url(${
                  empRelationMap?.empCooperates[i]?.isEmpower == 1
                    ? empRelationMap?.empCooperates[i]?.photo ?? nullImg
                    : nullImg
                })`,
                backgroundSize: 'cover',
              }"
            ></div>
            <div
              v-if="empRelationMap?.empCooperates?.length > 5"
              class="workship-relation-number"
            >
              <div class="workship-relation-amount">
                +{{ empRelationMap?.empCooperateCount - 1 }}
              </div>
            </div>
          </div>
          <div
            v-if="empRelationMap?.empCooperates?.length > 0"
            :style="
              empRelationMap?.empCooperates[0]?.isEmpower == 0
                ? `filter:blur(3px);`
                : ``
            "
            :class="
              isPc
                ? `workship-relation-friends-content-isPc`
                : isLargePc
                ? `workship-relation-friends-content-isLargePc`
                : `workship-relation-friends-content`
            "
          >
            <div
              :class="
                isPc
                  ? `workship-relation-name-isPc`
                  : isLargePc
                  ? `workship-relation-name-isLargePc`
                  : `workship-relation-name`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empCooperates[0]?.partnerCname ||
                    empRelationMap?.empCooperates[0]?.partnerEname
                  : empRelationMap?.empCooperates[0]?.partnerEname ||
                    empRelationMap?.empCooperates[0]?.partnerCname
              }}
            </div>
            <div
              :class="
                isPc
                  ? `workship-relation-position-isPc`
                  : isLargePc
                  ? `workship-relation-position-isLargePc`
                  : `workship-relation-position`
              "
            >
              {{
                lang === "cn"
                  ? empRelationMap?.empCooperates[0]?.dept ||
                    empRelationMap?.empCooperates[0]?.deptEname
                  : empRelationMap?.empCooperates[0]?.deptEname ||
                    empRelationMap?.empCooperates[0]?.dept
              }}
            </div>
          </div>
        </div>
        <div
          :class="
            isPad
              ? `workship-relation-footer-isPad`
              : `workship-relation-footer`
          "
        >
          <div class="workship-relation-footer-name">
            {{
              lang === "cn"
                ? empRelationMap?.emp?.cname || empRelationMap?.emp?.ename
                : empRelationMap?.emp?.ename || empRelationMap?.emp?.cname
            }}
          </div>
          <div class="workship-relation-footer-department">
            {{
              lang === "cn"
                ? empRelationMap?.emp?.dept || empRelationMap?.emp?.deptEname
                : empRelationMap?.emp?.deptEname || empRelationMap?.emp?.dept
            }}
          </div>
          <div class="workship-relation-footer-jobposition">
            {{
              lang === "cn"
                ? empRelationMap?.emp?.jobPositionCn ||
                  empRelationMap?.emp?.jobPosition
                : empRelationMap?.emp?.jobPosition ||
                  empRelationMap?.emp?.jobPositionCn
            }}
          </div>
          <div class="workship-relation-footer-updatetime">
            {{ empRelationMap?.emp?.etlTime + "\u00a0" }}
            {{ $t("workshipRelation.updateData") }}
          </div>
        </div>
      </div>

      <el-dialog
        :title="$t(titleValue)"
        :visible.sync="dialogVisible"
        width="50%"
        top="20vh"
      >
        <div class="sub-wrap">
          <div
            :style="
              item.isEmpower == 0
                ? `backdrop-filter: blur(0px);border-radius: 0px;z-index: 6;position: relative;`
                : `cursor: pointer`
            "
            class="workship-relation-item"
            v-for="(item, i) in workRelationList"
            :key="i"
            @click="
              linkMember(
                titleValue == 'workshipRelation.Partner'
                  ? item.partnerCdsid
                  : titleValue == 'workshipRelation.Directsubordinate'
                  ? item.directSubordinate
                  : item.cdsid,
                item
              )
            "
          >
            <img
              :style="item.isEmpower == 0 ? `filter: blur(2px);` : ``"
              v-if="titleValue == 'workshipRelation.Directsubordinate'"
              :src="item.subordinatePhoto || nullImg"
              alt=""
              class="item-pic"
            />
            <img
              :style="item.isEmpower == 0 ? `filter: blur(2px);` : ``"
              v-if="titleValue == 'workshipRelation.Partner'"
              :src="item.partnerPicture || nullImg"
              alt=""
              class="item-pic"
            />
            <img
              :style="item.isEmpower == 0 ? `filter: blur(2px);` : ``"
              v-if="
                titleValue != 'workshipRelation.Partner' &&
                titleValue != 'workshipRelation.Directsubordinate'
              "
              :src="item.photo || nullImg"
              alt=""
              class="item-pic"
            />
            <div
              :style="item.isEmpower == 0 ? `filter: blur(2px);` : ``"
              class="item-text fcjsb"
            >
              <div class="text-one">
                <span
                  v-if="titleValue == 'workshipRelation.Directsubordinate'"
                  class="name-text txt-elps"
                  >{{
                    lang === "cn"
                      ? item.subordinateCname || item.subordinateEname
                      : item.subordinateEname || item.subordinateCname
                  }}</span
                >
                <span
                  v-else-if="titleValue == 'workshipRelation.Partner'"
                  class="name-text txt-elps"
                  >{{
                    lang === "cn"
                      ? item.partnerCname || item.partnerEname
                      : item.partnerEname || item.partnerCname
                  }}</span
                >
                <span v-else class="name-text txt-elps">{{
                  lang === "cn"
                    ? item.cname || item.ename
                    : item.ename || item.cname
                }}</span>
                <em v-if="item.empGenerations" class="lable"
                  ><span>{{ item.empGenerations }}</span></em
                >
              </div>
              <div
                v-if="titleValue == 'workshipRelation.Directsubordinate'"
                class="text-thr"
              >
                {{
                  lang === "cn"
                    ? item.subordinatePositionCn || item.subordinatePosition
                    : item.subordinatePosition || item.subordinatePositionCn
                }}
              </div>
              <div
                v-else-if="titleValue == 'workshipRelation.Partner'"
                class="text-thr"
              >
                {{
                  lang === "cn"
                    ? item.partnerJobPositionCn || item.partnerJobPosition
                    : item.partnerJobPosition || item.partnerJobPositionCn
                }}
              </div>
              <div v-else class="text-thr">
                {{
                  lang === "cn"
                    ? item.jobPositionCn || item.jobPosition
                    : item.jobPosition || item.jobPositionCn
                }}
              </div>
            </div>
            <div
              class="workship-relation-item-noPower"
              v-if="item.isEmpower == 0"
            >
              <div class="workship-relation-item-noPower-text">
                {{ $t("workshipRelation.noPower") }}
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
      <!-- 同会人 -->
      <el-dialog
        :visible.sync="meetingDialog"
        width="69%"
        top="16vh"
        custom-class="meeting-dialog"
      >
        <div slot="title" class="f16">
          {{ $t(titleValue) }}
          <el-tooltip
            class="item"
            effect="dark"
            placement="right"
            popper-class="draw_share_atooltip"
          >
            <div v-html="$t('workshipRelation.data12')" slot="content"></div>
            <img src="@/src/assets/member/tips.png" alt="" class="tips"
          /></el-tooltip>
        </div>
        <div class="tab-block pot">
          <div
            class="tab-item c0 fjc"
            v-for="(item, i) in deptList"
            :key="i"
            :class="{ active: deptIndex == i }"
            @click="onTabs(i)"
          >
            {{
              utils.formatLang(
                lang,
                item.partnerDeptCnName,
                item.partnerDeptEnName
              )
            }}
            ({{ item.empCount }})
          </div>
        </div>
        <div
          class="sub-wrap"
          v-infinite-scroll="load"
          :infinite-scroll-delay="0"
          :infinite-scroll-distance="200"
          :infinite-scroll-disabled="disabled"
        >
          <div
            :style="
              item.isEmpower == 1
                ? `cursor: pointer`
                : `backdrop-filter: blur(0px);border-radius: 0px;z-index: 6;position: relative;`
            "
            class="workship-relation-item"
            v-for="(item, i) in userList"
            :key="i"
            @click="linkMember(item.partnerCdsid, item)"
          >
            <img
              :style="item.isEmpower == 0 ? `filter: blur(2px);` : ``"
              :src="item.partnerPicture || nullImg"
              alt=""
              class="item-pic"
              :class="{ 'dml-noPower-backop': item.isEmpower == 0 }"
            />
            <div
              class="item-text fcjsb"
              :class="{ 'dml-noPower-backop': item.isEmpower == 0 }"
            >
              <div class="text-one">
                <span class="name-text txt-elps">{{
                  utils.formatLang(lang, item.partnerCname, item.partnerEname)
                }}</span>
                <!-- <span v-else class="name-text txt-elps">{{
                item.subordinateEname || item.subordinateCname
              }}</span> -->
                <em v-if="item.partnerGenerations" class="lable"
                  ><span>{{ item.partnerGenerations }}</span></em
                >
              </div>
              <div class="text-thr">
                {{
                  utils.formatLang(
                    lang,
                    item.partnerDeptCnName,
                    item.partnerDeptEnName
                  )
                }}
              </div>
              <div class="text-thr">
                {{ $t("workshipRelation.meeting1") }}
                {{ item.commonMeetingNums }}
                {{ $t("workshipRelation.meeting2") }}
              </div>
            </div>
            <div
              class="workship-relation-item-noPower"
              v-if="item.isEmpower == 0"
            >
              <div class="workship-relation-item-noPower-text">
                {{ $t("workshipRelation.noPower") }}
              </div>
            </div>
          </div>
          <p
            v-if="noMore && total > 0 && !loading"
            class="end-wrap tac f16 pt10"
          >
            <span class="line"></span>
            <span class="txt">{{ $t("member.end") }}</span
            ><span class="line"></span>
          </p>
        </div>
        <div v-if="total === 0" class="user-empty tac">
          <img src="@/src/assets/member/user-empty.png" alt="" class="mb16" />
          <p class="f14 tac">{{ $t("member.NoResults") }}</p>
        </div>
      </el-dialog>
    </div>

    <router-view v-if="!pageDetailHide" />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "workshipRelation",
  data() {
    return {
      tabIndex: 0,
      dialogVisible: false,
      titleValue: "",
      empRelationMap: {},
      id: null,
      workRelationList: [],
      nullImg: require("@/src/assets/common/defaultImg.png"),
      subordinateFirstPhoto: "",
      cooperatesFirstPhoto: "",
      alumniFirstPhoto: "",
      peersFirstPhoto: "",
      friendsFirstPhoto: "",
      windowWidth: document.documentElement.clientWidth, //获取屏幕宽度
      isPc: false,
      isLargePc: false,
      isPad: false,

      pageDetailHide: true,
      pageFirstVisit: true,
      refreshStatus: false,

      meetingDialog: false,
      deptList: [],
      userList: [],
      deptIndex: 0,
      current: 1,
      size: 20,
      totalPage: 0,
      total: 0,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
      noMore() {
        return this.current >= this.totalPage;
      },
      disabled() {
        return this.loading || this.noMore;
      },
    }),
  },
  watch: {
    "$route.path"(val) {
      if (val.indexOf("memberDetail") != -1) {
        this.pageDetailHide = false;
      } else {
        this.pageDetailHide = true;

        if (this.pageFirstVisit) {
          this.pageFirstVisit = false;
          this.refreshStatus = true;

          this.initNew();
        }
      }
    },
    "$route.query.id": {
      immediate: true,
      handler(val) {
        if (val == undefined) {
          return;
        }
        this.id = this.utils.decryption(val);
        if (this.id) {
          this.getWorkRelationMap().then(() => {
            this.subordinateFirstPhoto =
              this.empRelationMap?.empSubordinates[0]?.subordinatePhoto == "" ||
              this.empRelationMap?.empSubordinates[0]?.subordinatePhoto == null
                ? this.nullImg
                : this.empRelationMap?.empSubordinates[0]?.subordinatePhoto;
            this.cooperatesFirstPhoto =
              this.empRelationMap?.empCooperates[0]?.partnerPicture == null ||
              this.empRelationMap?.empCooperates[0]?.partnerPicture == ""
                ? this.nullImg
                : this.empRelationMap?.empCooperates[0]?.partnerPicture;
            this.peersFirstPhoto =
              this.empRelationMap?.empTeamPeer[0]?.photo == null ||
              this.empRelationMap?.empTeamPeer[0]?.photo == ""
                ? this.nullImg
                : this.empRelationMap?.empTeamPeer[0]?.photo;
            this.alumniFirstPhoto =
              this.empRelationMap?.empAlumni[0]?.photo == null ||
              this.empRelationMap?.empAlumni[0]?.photo == ""
                ? this.nullImg
                : this.empRelationMap?.empAlumni[0]?.photo;
            this.friendsFirstPhoto =
              this.empRelationMap?.empFriends[0]?.photo == null ||
              this.empRelationMap?.empFriends[0]?.photo == ""
                ? this.nullImg
                : this.empRelationMap?.empFriends[0]?.photo;
          });
        }
      },
    },
  },
  mounted() {
    if (this.$route.path.indexOf("memberDetail") != -1) {
      this.pageDetailHide = false;
    } else {
      this.pageDetailHide = true;

      if (this.pageFirstVisit) {
        this.pageFirstVisit = false;
        this.refreshStatus = true;

        this.initNew();
      }
    }
  },
  beforeDestroy() {
    if (!this.isPad) {
      window.removeEventListener("resize", this.windowZoomChange);
    }
  },
  methods: {
    ...mapActions({
      workRelationMap: "emp/workRelationMap",
      colleagueCount: "emp/colleagueCount",
      colleagueList: "emp/colleagueList",
    }),
    initNew() {
      this.determineDevice();
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth > 1200 && this.windowWidth < 1600 && !this.isPad) {
        this.isPc = true;
      }
      if (this.windowWidth > 1600 && !this.isPad) {
        this.isLargePc = true;
      }
      this.id = this.utils.decryption(this.$route.query.id);
      this.getWorkRelationMap().then(() => {
        this.subordinateFirstPhoto =
          this.empRelationMap?.empSubordinates[0]?.subordinatePhoto == "" ||
          this.empRelationMap?.empSubordinates[0]?.subordinatePhoto == null
            ? this.nullImg
            : this.empRelationMap?.empSubordinates[0]?.subordinatePhoto;
        this.cooperatesFirstPhoto =
          this.empRelationMap?.empCooperates[0]?.partnerPicture == null ||
          this.empRelationMap?.empCooperates[0]?.partnerPicture == ""
            ? this.nullImg
            : this.empRelationMap?.empCooperates[0]?.partnerPicture;
        this.peersFirstPhoto =
          this.empRelationMap?.empTeamPeer[0]?.photo == null ||
          this.empRelationMap?.empTeamPeer[0]?.photo == ""
            ? this.nullImg
            : this.empRelationMap?.empTeamPeer[0]?.photo;
        this.alumniFirstPhoto =
          this.empRelationMap?.empAlumni[0]?.photo == null ||
          this.empRelationMap?.empAlumni[0]?.photo == ""
            ? this.nullImg
            : this.empRelationMap?.empAlumni[0]?.photo;
        this.friendsFirstPhoto =
          this.empRelationMap?.empFriends[0]?.photo == null ||
          this.empRelationMap?.empFriends[0]?.photo == ""
            ? this.nullImg
            : this.empRelationMap?.empFriends[0]?.photo;
      });
    },
    determineDevice() {
      var agent = navigator.userAgent.toLowerCase();
      var ipad = agent.indexOf("ipad");
      var pad = agent.indexOf("pad");
      if (pad != -1 || ipad != -1) {
        this.isPad = true;
      } else {
        this.isPad = false;
        var macApp = window.navigator.userAgent.match(/Macintosh/i) != null;
        if (macApp) {
          var canvas = document.createElement("canvas");
          if (canvas != null) {
            var context =
              canvas.getContext("webgl") ||
              canvas.getContext("experimental-webgl");
            if (context) {
              var info = context.getExtension("WEBGL_debug_renderer_info");
              if (info) {
                var renderer = context.getParameter(
                  info.UNMASKED_RENDERER_WEBGL
                );
                if (renderer.indexOf("Apple") != -1) {
                  this.isPad = true;
                }
              }
            }
          }
        }
        if (!this.isPad) {
          window.addEventListener("resize", this.windowZoomChange);
        }
      }
    },
    windowZoomChange() {
      this.determineDevice();
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth > 1200 && this.windowWidth < 1600 && !this.isPad) {
        this.isPc = true;
      }
      if (this.windowWidth > 1600 && !this.isPad) {
        this.isLargePc = true;
      }
      window.location.reload();
    },
    onTab(val) {
      this.tabIndex = val;
    },
    linkMember(itemValue, itemDetail) {
      if (itemDetail.isEmpower) {
        this.dialogVisible = false;
        this.meetingDialog = false;
        let sercetCd = this.utils.encryption(itemValue);

        let thispath = this.$route.path;
        this.$router.push({
          path: thispath + "/memberDetail",
          query: { id: sercetCd },
        });
      }
    },
    onNodeClick(value) {
      if (value == 1) {
        this.titleValue = "workshipRelation.Alumni";
        this.workRelationList = this.empRelationMap.empAlumni;
      } else if (value == 2) {
        this.linkMember(
          this.empRelationMap.empLeader.cdsid,
          this.empRelationMap.empLeader
        );
        return;
      } else if (value == 6) {
        this.titleValue = "workshipRelation.Friends";
        this.workRelationList = this.empRelationMap.empFriends;
      } else if (value == 3) {
        this.titleValue = "workshipRelation.Directsubordinate";
        this.workRelationList = this.empRelationMap.empSubordinates;
      } else if (value == 4) {
        this.titleValue = "workshipRelation.Peers";
        this.workRelationList = this.empRelationMap.empTeamPeer;
      } else if (value == 5) {
        this.titleValue = "workshipRelation.Partner";
        //this.workRelationList = this.empRelationMap.empCooperates;
        if (
          this.empRelationMap.empCooperates &&
          this.empRelationMap.empCooperates.length
        ) {
          this.getDept();
        }
      } else {
        this.workRelationList = [];
      }
      if (this.workRelationList.length == 0) {
        return;
      }
      this.dialogVisible = true;
    },
    async getWorkRelationMap() {
      let res = await this.workRelationMap(this.id);
      this.empRelationMap = res.data;
    },
    async getDept() {
      let res = await this.colleagueCount(this.id);
      this.deptList = res.data;
      this.getUsers(null);
      this.meetingDialog = true;
    },
    load() {
      this.current++;
      this.getUsers();
    },
    async getUsers(val) {
      if (val) {
        this.current = 1;
        this.userList = [];
      }
      this.loading = true;
      let deptId = this.deptList[this.deptIndex].partnerDeptId;
      let params = {
        current: this.current,
        size: this.size,
        partnerDeptId: deptId,
        cdsid: this.id,
      };
      let res = await this.colleagueList(params);
      this.userList = [...this.userList, ...res.data.records];
      this.current = res.data.current;
      this.total = res.data.total;
      this.totalPage = parseInt(this.total / this.size);
      let num = this.total % this.size;
      if (num > 0) {
        this.totalPage += 1;
      }
      this.loading = false;
    },
    onTabs(val) {
      this.deptIndex = val;
      this.getUsers(1);
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.workship-relation {
  .edp-header {
    background-color: white;
  }
  .el-dialog {
    border-radius: 8px;
    width: toPad(686);
    height: auto;
    //  margin-top: 0 !important;
    //top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
  .el-dialog__body {
    height: auto;
    padding: 0 toPad(20) toPad(20);
  }
  .el-dialog__title {
    font-size: toPad(18);
    line-height: 119.6%;
    color: #000000;
  }
  .workship-relation-wrap {
    border: 1px solid red;
    height: toPad(600);
    display: flex;
    position: relative;
  }
  .workship-relation-title {
    font-size: toPad(26);
    line-height: toPad(30);
    color: #000000;
    font-weight: 600;
  }
  .workship-relation-icon {
    margin-right: toPad(7);
  }
  .workship-relation-remark {
    background: rgba(97, 191, 180, 0.1);
    margin-top: toPad(0);
    margin-left: toPad(40);
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
  }
  .workship-relation-item-noPower {
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    width: fit-content;
    width: -moz-fit-content;
    padding-left: 20px;
    padding-right: 20px;
    height: toPad(68);
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(2));
    border-radius: toPad(6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .workship-relation-item-noPower-text {
    width: toPad(250);
    height: auto;
    max-height: toPad(60);
    position: relative;
    font-family: "JLR Emeric";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: toPad(17);
    justify-content: center;
    align-content: center;
    text-align: center;
    color: #043c27;
    overflow: hidden;
  }
  .workship-relation-titleen {
    position: absolute;
    font-size: toPad(26);
    line-height: toPad(30);
    color: #000000;
    font-weight: 600;
  }
  .workship-relation-graph {
    margin-top: toPad(264);
    margin-left: toPad(532);
    width: toPad(176);
    height: toPad(176);
    position: absolute;
    border: 2px solid #ffffff;
    filter: drop-shadow(0px 4px 20px rgba(62, 136, 109, 0.15));
    border-radius: toPad(435);
    z-index: 1;
    animation: 1s slowly-out-graph forwards;
  }
  .workship-relation-graph-isPc {
    margin-top: toPad(165);
    margin-left: toPad(532);
    width: toPad(123);
    height: toPad(123);
    position: absolute;
    border: 2px solid #ffffff;
    filter: drop-shadow(0px 4px 20px rgba(62, 136, 109, 0.15));
    border-radius: toPad(304);
    z-index: 1;
    animation: 1s slowly-out-graph forwards;
  }
  .workship-relation-graph-isLargePc {
    margin-top: toPad(245);
    margin-left: toPad(682);
    width: toPad(123);
    height: toPad(123);
    position: absolute;
    border: 2px solid #ffffff;
    filter: drop-shadow(0px 4px 20px rgba(62, 136, 109, 0.15));
    border-radius: toPad(304);
    z-index: 1;
    animation: 1s slowly-out-graph forwards;
  }
  @keyframes slowly-out-graph {
    0% {
      border: 2px solid #ffffff;
      opacity: 0;
    }
    30% {
      border: 2px solid pink;
      opacity: 1;
    }
    60% {
      border: 2px solid #ffffff;
      opacity: 0;
    }
    80% {
      border: 2px solid pink;
      opacity: 1;
    }
    100% {
      border: 2px solid #ffffff;
      opacity: 1;
    }
  }
  .workship-relation-alumni-wrap {
    position: absolute;
    left: toPad(610);
    top: toPad(186);
    width: toPad(60);
    height: toPad(160);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-alumni-wrap-isPc {
    position: absolute;
    left: toPad(605);
    top: toPad(70);
    width: toPad(42);
    height: toPad(162);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-alumni-wrap-isLargePc {
    position: absolute;
    left: toPad(755);
    top: toPad(150);
    width: toPad(42);
    height: toPad(162);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  @keyframes slowly-out-alumni {
    0% {
      top: toPad(236);
    }
    100% {
      top: toPad(186);
    }
  }
  .workship-relation-alumni {
    margin-top: toPad(0);
    margin-left: toPad(535);
    width: toPad(130);
    height: toPad(130);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    animation: 1s slowly-slide-alumni forwards;
  }
  .workship-relation-alumni-isPc {
    margin-top: toPad(-30);
    margin-left: toPad(535);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(304);
    animation: 1s slowly-slide-alumni-isPc forwards;
  }
  .workship-relation-alumni-isLargePc {
    margin-top: toPad(50);
    margin-left: toPad(685);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(304);
    animation: 1s slowly-slide-alumni-isLargePc forwards;
  }
  .workship-relation-alumni-noPower {
    width: toPad(130);
    height: toPad(130);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .workship-relation-noPower-text {
    width: toPad(120);
    height: toPad(44);
    max-height: toPad(44);
    position: relative;
    font-family: "JLR Emeric";
    font-style: normal;
    font-weight: 400;
    font-size: toPad(10);
    line-height: toPad(12);
    justify-content: center;
    align-content: center;
    text-align: center;
    color: #043c27;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .workship-relation-noPower-text-isPc {
    width: toPad(120);
    height: toPad(77);
    max-height: toPad(77);
    position: relative;
    font-family: "JLR Emeric";
    font-style: normal;
    font-weight: 400;
    font-size: toPad(10);
    line-height: toPad(14);
    justify-content: center;
    align-content: center;
    text-align: center;
    color: #043c27;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .workship-relation-noPower-text-isLargePc {
    width: toPad(120);
    height: toPad(77);
    max-height: toPad(77);
    position: relative;
    font-family: "JLR Emeric";
    font-style: normal;
    font-weight: 400;
    font-size: toPad(10);
    line-height: toPad(14);
    justify-content: center;
    align-content: center;
    text-align: center;
    color: #043c27;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes slowly-slide-alumni {
    0% {
      margin-top: toPad(264);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(0);
      margin-left: toPad(535);
    }
  }
  @keyframes slowly-slide-alumni-isPc {
    0% {
      margin-top: toPad(165);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(-30);
      margin-left: toPad(535);
    }
  }
  @keyframes slowly-slide-alumni-isLargePc {
    0% {
      margin-top: toPad(245);
      margin-left: toPad(682);
    }
    100% {
      margin-top: toPad(50);
      margin-left: toPad(685);
    }
  }
  .workship-relation-alumni-photos {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(92);
    margin-left: toPad(630);
    display: inline-flex;
    z-index: 2;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-alumni-photos-isPc {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(20);
    margin-left: toPad(608);
    display: inline-flex;
    z-index: 2;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-alumni-photos-isLargePc {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(100);
    margin-left: toPad(758);
    display: inline-flex;
    z-index: 2;
    animation: 2s opacity-out forwards;
  }
  @keyframes opacity-out {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .workship-relation-alumni-content {
    position: absolute;
    width: auto;
    height: toPad(41);
    // margin-top: toPad(86);
    margin-top: toPad(24);
    margin-left: toPad(680);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-alumni-content-isPc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(-24);
    margin-left: toPad(640);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-alumni-content-isLargePc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(56);
    margin-left: toPad(790);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-name {
    font-size: toPad(20);
    font-weight: 600;
    line-height: 137.4%;
    color: #9b8062;
  }
  .workship-relation-name-isPc {
    font-size: toPad(16);
    font-weight: 600;
    line-height: 137.4%;
    color: #9b8062;
  }
  .workship-relation-name-isLargePc {
    font-size: toPad(16);
    font-weight: 600;
    line-height: 137.4%;
    color: #9b8062;
  }
  .workship-relation-position {
    font-weight: 400;
    font-size: toPad(16);
    line-height: toPad(20);
    letter-spacing: toPad(0.5);
    text-transform: uppercase;
    color: #000000;
  }
  .workship-relation-position-isPc {
    font-weight: 400;
    font-size: toPad(12);
    line-height: toPad(20);
    letter-spacing: toPad(0.5);
    text-transform: uppercase;
    color: #000000;
  }
  .workship-relation-position-isLargePc {
    font-weight: 400;
    font-size: toPad(12);
    line-height: toPad(20);
    letter-spacing: toPad(0.5);
    text-transform: uppercase;
    color: #000000;
  }
  .workship-relation-alumni-elliptic {
    position: relative;
    width: toPad(56);
    height: toPad(25);
    margin-top: -170%;
    margin-left: toPad(1);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(88deg);
  }
  .workship-relation-alumni-elliptic-isPc {
    position: relative;
    width: toPad(48);
    height: toPad(25);
    margin-top: -170%;
    margin-left: toPad(-3);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(88deg);
  }
  .workship-relation-alumni-elliptic-isLargePc {
    position: relative;
    width: toPad(48);
    height: toPad(25);
    margin-top: -170%;
    margin-left: toPad(-3);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(88deg);
  }
  .workship-relation-alumni-elliptic-en {
    position: relative;
    width: toPad(78);
    height: toPad(22);
    margin-top: -170%;
    margin-left: toPad(-10);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(88deg);
  }
  .workship-relation-alumni-elliptic-en-isPc {
    position: relative;
    width: toPad(56);
    height: toPad(18);
    margin-top: -170%;
    margin-left: toPad(-6);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(88deg);
  }
  .workship-relation-alumni-elliptic-en-isLargePc {
    position: relative;
    width: toPad(56);
    height: toPad(18);
    margin-top: -170%;
    margin-left: toPad(-6);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(88deg);
  }
  .workship-relation-alumni-text {
    width: toPad(36);
    height: toPad(25);
    font-size: toPad(16);
    line-height: toPad(25);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-alumni-text-isPc {
    width: toPad(32);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-alumni-text-isLargePc {
    width: toPad(32);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-alumni-text-en {
    width: toPad(58);
    height: toPad(22);
    font-size: toPad(16);
    line-height: toPad(22);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-alumni-text-en-isPc {
    width: toPad(40);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-alumni-text-en-isLargePc {
    width: toPad(40);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-leader-wrap {
    position: absolute;
    left: toPad(360);
    top: toPad(312);
    width: toPad(260);
    height: toPad(100);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-leader-wrap-isPc {
    position: absolute;
    left: toPad(340);
    top: toPad(180);
    width: toPad(280);
    height: toPad(100);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-leader-wrap-isLargePc {
    position: absolute;
    left: toPad(490);
    top: toPad(260);
    width: toPad(280);
    height: toPad(100);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  @keyframes slowly-out-leader {
    0% {
      left: toPad(300);
    }
    100% {
      left: toPad(360);
    }
  }
  .workship-relation-leader-elliptic {
    position: relative;
    width: toPad(56);
    height: toPad(25);
    margin-top: -26%;
    margin-left: toPad(100);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(22.29deg);
  }
  .workship-relation-leader-elliptic-isPc {
    position: relative;
    width: toPad(48);
    height: toPad(25);
    margin-top: -26%;
    margin-left: toPad(100);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(22.29deg);
  }
  .workship-relation-leader-elliptic-isLargePc {
    position: relative;
    width: toPad(48);
    height: toPad(25);
    margin-top: -26%;
    margin-left: toPad(100);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(22.29deg);
  }
  .workship-relation-leader-elliptic-en {
    position: relative;
    width: toPad(131);
    height: toPad(22);
    margin-top: -25%;
    margin-left: toPad(65);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(22.29deg);
  }
  .workship-relation-leader-elliptic-en-isPc {
    position: relative;
    width: toPad(94);
    height: toPad(18);
    margin-top: -24%;
    margin-left: toPad(85);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(22.29deg);
  }
  .workship-relation-leader-elliptic-en-isLargePc {
    position: relative;
    width: toPad(94);
    height: toPad(18);
    margin-top: -24%;
    margin-left: toPad(85);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(22.29deg);
  }
  .workship-relation-leader-text {
    width: toPad(36);
    height: toPad(25);
    font-size: toPad(16);
    line-height: toPad(25);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-leader-text-isPc {
    width: toPad(36);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-leader-text-isLargePc {
    width: toPad(36);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-leader-text-en {
    width: toPad(101);
    height: toPad(22);
    font-size: toPad(16);
    line-height: toPad(22);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-leader-text-en-isPc {
    width: toPad(82);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-leader-text-en-isLargePc {
    width: toPad(82);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-leader {
    margin-top: toPad(157);
    margin-left: toPad(246);
    width: toPad(130);
    height: toPad(130);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-leader forwards;
  }
  .workship-relation-leader-isPc {
    margin-top: toPad(70);
    margin-left: toPad(266);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-leader-isPc forwards;
  }
  .workship-relation-leader-isLargePc {
    margin-top: toPad(150);
    margin-left: toPad(416);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-leader-isLargePc forwards;
  }
  .workship-relation-leader-noPower {
    width: toPad(130);
    height: toPad(130);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .workship-relation-leader-noPower-isPc {
    width: toPad(90);
    height: toPad(90);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .workship-relation-leader-noPower-isLargePc {
    width: toPad(90);
    height: toPad(90);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  @keyframes slowly-slide-leader {
    0% {
      margin-top: toPad(264);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(157);
      margin-left: toPad(246);
    }
  }
  @keyframes slowly-slide-leader-isPc {
    0% {
      margin-top: toPad(165);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(70);
      margin-left: toPad(266);
    }
  }
  @keyframes slowly-slide-leader-isLargePc {
    0% {
      margin-top: toPad(245);
      margin-left: toPad(682);
    }
    100% {
      margin-top: toPad(150);
      margin-left: toPad(416);
    }
  }
  .workship-relation-leader-content {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(201);
    margin-left: toPad(398);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-leader-content-isPc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(90);
    margin-left: toPad(370);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-leader-content-isLargePc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(170);
    margin-left: toPad(520);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-subordinates-wrap {
    position: absolute;
    left: toPad(360);
    top: toPad(479);
    width: toPad(260);
    height: toPad(200);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-subordinates-wrap-isPc {
    position: absolute;
    left: toPad(360);
    top: toPad(300);
    width: toPad(260);
    height: toPad(180);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-subordinates-wrap-isLargePc {
    position: absolute;
    left: toPad(510);
    top: toPad(380);
    width: toPad(260);
    height: toPad(180);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  @keyframes slowly-out-subordinates {
    0% {
      left: toPad(300);
    }
    100% {
      left: toPad(360);
    }
  }
  .workship-relation-subordinates-elliptic {
    position: relative;
    width: toPad(91);
    height: toPad(25);
    margin-top: -53%;
    margin-left: toPad(112);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(-38.88deg);
  }
  .workship-relation-subordinates-elliptic-isPc {
    position: relative;
    width: toPad(72);
    height: toPad(25);
    margin-top: -50%;
    margin-left: toPad(122);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(-38.88deg);
  }
  .workship-relation-subordinates-elliptic-isLargePc {
    position: relative;
    width: toPad(72);
    height: toPad(25);
    margin-top: -50%;
    margin-left: toPad(122);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(-38.88deg);
  }
  .workship-relation-subordinates-elliptic-en {
    position: relative;
    width: toPad(134);
    height: toPad(22);
    margin-top: -53%;
    margin-left: toPad(92);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(-38.88deg);
  }
  .workship-relation-subordinates-elliptic-en-isPc {
    position: relative;
    width: toPad(96);
    height: toPad(18);
    margin-top: -43%;
    margin-left: toPad(93);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(-38.88deg);
  }
  .workship-relation-subordinates-elliptic-en-isLargePc {
    position: relative;
    width: toPad(96);
    height: toPad(18);
    margin-top: -43%;
    margin-left: toPad(93);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(-38.88deg);
  }
  .workship-relation-subordinates-text {
    width: toPad(72);
    height: toPad(25);
    font-size: toPad(16);
    line-height: toPad(25);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-subordinates-text-isPc {
    width: toPad(72);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-subordinates-text-isLargePc {
    width: toPad(72);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-subordinates-text-en {
    width: toPad(104);
    height: toPad(22);
    font-size: toPad(16);
    line-height: toPad(22);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-subordinates-text-en-isPc {
    width: toPad(104);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-subordinates-text-en-isLargePc {
    width: toPad(104);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-subordinates {
    margin-top: toPad(526);
    margin-left: toPad(291);
    width: toPad(130);
    height: toPad(130);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-subordinates forwards;
  }
  .workship-relation-subordinates-isPc {
    margin-top: toPad(370);
    margin-left: toPad(291);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-subordinates-isPc forwards;
  }
  .workship-relation-subordinates-isLargePc {
    margin-top: toPad(450);
    margin-left: toPad(441);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-subordinates-isLargePc forwards;
  }
  .workship-relation-subordinates-noPower {
    width: toPad(130);
    height: toPad(130);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .workship-relation-subordinates-noPower-isPc {
    width: toPad(90);
    height: toPad(90);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .workship-relation-subordinates-noPower-isLargePc {
    width: toPad(90);
    height: toPad(90);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  @keyframes slowly-slide-subordinates {
    0% {
      margin-top: toPad(264);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(526);
      margin-left: toPad(291);
    }
  }
  @keyframes slowly-slide-subordinates-isPc {
    0% {
      margin-top: toPad(165);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(370);
      margin-left: toPad(291);
    }
  }
  @keyframes slowly-slide-subordinates-isLargePc {
    0% {
      margin-top: toPad(245);
      margin-left: toPad(682);
    }
    100% {
      margin-top: toPad(450);
      margin-left: toPad(441);
    }
  }
  .workship-relation-subordinates-content {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(565);
    margin-left: toPad(435);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-subordinates-content-isPc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(380);
    margin-left: toPad(395);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-subordinates-content-isLargePc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(460);
    margin-left: toPad(545);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-subordinates-photos {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(617);
    margin-left: toPad(385);
    display: inline-flex;
    z-index: 3;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-subordinates-photos-isPc {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(420);
    margin-left: toPad(363);
    display: inline-flex;
    z-index: 3;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-subordinates-photos-isLargePc {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(500);
    margin-left: toPad(513);
    display: inline-flex;
    z-index: 3;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-photos1 {
    position: relative;
    width: toPad(40);
    height: toPad(40);
    border: 1px solid #ffffff;
    border-radius: 345px;
  }
  .workship-relation-photosn {
    position: relative;
    width: toPad(40);
    height: toPad(40);
    margin-left: toPad(-25);
    border: 1px solid #ffffff;
    border-radius: 345px;
  }
  .workship-relation-number {
    position: relative;
    width: toPad(40);
    height: toPad(40);
    margin-left: toPad(-25);
    background: linear-gradient(180deg, #f7f9e8 0%, #eaf1eb 100%);
    border: 1px solid #ffffff;
    border-radius: toPad(345);
  }
  .workship-relation-amount {
    position: relative;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: toPad(22);
    color: #3e886d;
    width: toPad(29);
    height: toPad(22);
    margin: toPad(8) auto auto auto;
  }
  .workship-relation-peers-wrap {
    position: absolute;
    left: toPad(720);
    top: toPad(480);
    width: toPad(100);
    height: toPad(180);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-peers-wrap-isPc {
    position: absolute;
    left: toPad(660);
    top: toPad(320);
    width: toPad(100);
    height: toPad(180);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-peers-wrap-isLargePc {
    position: absolute;
    left: toPad(810);
    top: toPad(400);
    width: toPad(100);
    height: toPad(180);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  @keyframes slowly-out-peers {
    0% {
      top: toPad(436);
    }
    100% {
      top: toPad(496);
    }
  }
  .workship-relation-peers-elliptic {
    position: relative;
    width: toPad(110);
    height: toPad(25);
    margin-top: -112%;
    margin-left: toPad(-10);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(60.8deg);
  }
  .workship-relation-peers-elliptic-isPc {
    position: relative;
    width: toPad(86);
    height: toPad(25);
    margin-top: -112%;
    margin-left: toPad(3);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(60.8deg);
  }
  .workship-relation-peers-elliptic-isLargePc {
    position: relative;
    width: toPad(86);
    height: toPad(25);
    margin-top: -112%;
    margin-left: toPad(3);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(60.8deg);
  }
  .workship-relation-peers-elliptic-en {
    position: relative;
    width: toPad(67);
    height: toPad(22);
    margin-top: -112%;
    margin-left: toPad(12);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(60.8deg);
  }
  .workship-relation-peers-elliptic-en-isPc {
    position: relative;
    width: toPad(54);
    height: toPad(18);
    margin-top: -112%;
    margin-left: toPad(17);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(60.8deg);
  }
  .workship-relation-peers-elliptic-en-isLargePc {
    position: relative;
    width: toPad(54);
    height: toPad(18);
    margin-top: -112%;
    margin-left: toPad(17);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(60.8deg);
  }
  .workship-relation-peers-text {
    width: toPad(90);
    height: toPad(25);
    font-size: toPad(16);
    line-height: toPad(25);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-peers-text-isPc {
    width: toPad(90);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  Pc {
    position: relative;
    width: toPad(54);
    height: toPad(18);
    margin-top: -112%;
    margin-left: toPad(17);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(60.8deg);
  }
  .workship-relation-peers-text {
    width: toPad(90);
    height: toPad(25);
    font-size: toPad(16);
    line-height: toPad(25);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-peers-text-isLargePc {
    width: toPad(90);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-peers-text-en {
    width: toPad(47);
    height: toPad(22);
    font-size: toPad(16);
    line-height: toPad(22);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-peers-text-en-isPc {
    width: toPad(47);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-peers-text-en-isLargePc {
    width: toPad(47);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-peers {
    margin-top: toPad(575);
    margin-left: toPad(711);
    width: toPad(130);
    height: toPad(130);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-peers forwards;
  }
  .workship-relation-peers-isPc {
    margin-top: toPad(400);
    margin-left: toPad(680);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-peers-isPc forwards;
  }
  .workship-relation-peers-isLargePc {
    margin-top: toPad(480);
    margin-left: toPad(830);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-peers-isLargePc forwards;
  }
  .workship-relation-peers-noPower {
    width: toPad(130);
    height: toPad(130);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .workship-relation-peers-noPower-isPc {
    width: toPad(90);
    height: toPad(90);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .workship-relation-peers-noPower-isLargePc {
    width: toPad(90);
    height: toPad(90);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  @keyframes slowly-slide-peers {
    0% {
      margin-top: toPad(264);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(575);
      margin-left: toPad(711);
    }
  }
  @keyframes slowly-slide-peers-isPc {
    0% {
      margin-top: toPad(165);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(400);
      margin-left: toPad(680);
    }
  }
  @keyframes slowly-slide-peers-isLargePc {
    0% {
      margin-top: toPad(245);
      margin-left: toPad(682);
    }
    100% {
      margin-top: toPad(480);
      margin-left: toPad(830);
    }
  }
  .workship-relation-peers-photos {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(665);
    margin-left: toPad(806);
    display: inline-flex;
    z-index: 3;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-peers-photos-isPc {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(450);
    margin-left: toPad(753);
    display: inline-flex;
    z-index: 3;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-peers-photos-isLargePc {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(530);
    margin-left: toPad(903);
    display: inline-flex;
    z-index: 3;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-peers-content {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(597);
    margin-left: toPad(850);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-peers-content-isPc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(410);
    margin-left: toPad(780);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-peers-content-isLargePc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(490);
    margin-left: toPad(930);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-partners-wrap {
    position: absolute;
    left: toPad(720);
    top: toPad(436);
    width: toPad(260);
    height: toPad(100);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-partners-wrap-isPc {
    position: absolute;
    left: toPad(650);
    top: toPad(280);
    width: toPad(260);
    height: toPad(100);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  Pc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(410);
    margin-left: toPad(780);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-partners-wrap {
    position: absolute;
    left: toPad(720);
    top: toPad(436);
    width: toPad(260);
    height: toPad(100);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-partners-wrap-isLargePc {
    position: absolute;
    left: toPad(800);
    top: toPad(360);
    width: toPad(260);
    height: toPad(100);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  @keyframes slowly-out-partners {
    0% {
      left: toPad(660);
    }
    100% {
      left: toPad(720);
    }
  }
  .workship-relation-partners-elliptic {
    position: relative;
    width: toPad(147);
    height: toPad(25);
    margin-top: -25%;
    margin-left: toPad(60);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(17.35deg);
  }
  .workship-relation-partners-elliptic-isPc {
    position: relative;
    width: toPad(107);
    height: toPad(25);
    margin-top: -25%;
    margin-left: toPad(75);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(17.35deg);
  }
  .workship-relation-partners-elliptic-isLargePc {
    position: relative;
    width: toPad(107);
    height: toPad(25);
    margin-top: -25%;
    margin-left: toPad(75);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(17.35deg);
  }
  .workship-relation-partners-elliptic-en {
    position: relative;
    width: toPad(141);
    height: toPad(22);
    margin-top: -25%;
    margin-left: toPad(60);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(17.35deg);
  }
  .workship-relation-partners-elliptic-en-isPc {
    position: relative;
    width: toPad(100);
    height: toPad(18);
    margin-top: -24%;
    margin-left: toPad(80);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(17.35deg);
  }
  .workship-relation-partners-elliptic-en-isLargePc {
    position: relative;
    width: toPad(100);
    height: toPad(18);
    margin-top: -24%;
    margin-left: toPad(80);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: toPad(23);
    transform: rotate(17.35deg);
  }
  .workship-relation-partners-text {
    width: toPad(127);
    height: toPad(25);
    font-size: toPad(16);
    line-height: toPad(25);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-partners-text-isPc {
    width: toPad(127);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-partners-text-isLargePc {
    width: toPad(127);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-partners-text-en {
    width: toPad(121);
    height: toPad(22);
    font-size: toPad(16);
    line-height: toPad(22);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-partners-text-en-isPc {
    width: toPad(121);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-partners-text-en-isLargePc {
    width: toPad(121);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-partners {
    margin-top: toPad(410);
    margin-left: toPad(916);
    width: toPad(130);
    height: toPad(130);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-partners forwards;
  }
  .workship-relation-partners-isPc {
    margin-top: toPad(270);
    margin-left: toPad(830);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-partners-isPc forwards;
  }
  .workship-relation-partners-isLargePc {
    margin-top: toPad(350);
    margin-left: toPad(980);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-partners-isLargePc forwards;
  }
  .workship-relation-partners-noPower {
    width: toPad(130);
    height: toPad(130);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .workship-relation-partners-noPower-isPc {
    width: toPad(90);
    height: toPad(90);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .workship-relation-partners-noPower-isLargePc {
    width: toPad(90);
    height: toPad(90);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  @keyframes slowly-slide-partners {
    0% {
      margin-top: toPad(264);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(410);
      margin-left: toPad(916);
    }
  }
  @keyframes slowly-slide-partners-isPc {
    0% {
      margin-top: toPad(165);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(270);
      margin-left: toPad(830);
    }
  }
  @keyframes slowly-slide-partners-isLargePc {
    0% {
      margin-top: toPad(245);
      margin-left: toPad(682);
    }
    100% {
      margin-top: toPad(350);
      margin-left: toPad(980);
    }
  }
  .workship-relation-partners-photos {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(500);
    margin-left: toPad(1011);
    display: inline-flex;
    z-index: 2;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-partners-photos-isPc {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(320);
    margin-left: toPad(903);
    display: inline-flex;
    z-index: 2;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-partners-photos-isLargePc {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(400);
    margin-left: toPad(1053);
    display: inline-flex;
    z-index: 2;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-partners-content {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(433);
    margin-left: toPad(1060);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-partners-content-isPc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(280);
    margin-left: toPad(930);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-partners-content-isLargePc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(360);
    margin-left: toPad(1080);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-friends-wrap {
    position: absolute;
    left: toPad(720);
    top: toPad(224);
    width: toPad(260);
    height: toPad(180);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-friends-wrap-isPc {
    position: absolute;
    left: toPad(660);
    top: toPad(130);
    width: toPad(260);
    height: toPad(180);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-friends-wrap-isLargePc {
    position: absolute;
    left: toPad(810);
    top: toPad(190);
    width: toPad(260);
    height: toPad(180);
    z-index: 0;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-line {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .workship-relation-friends-elliptic {
    position: relative;
    width: toPad(75);
    height: toPad(25);
    margin-top: -43%;
    margin-left: toPad(100);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(-29.8deg);
  }
  .workship-relation-friends-elliptic-isPc {
    position: relative;
    width: toPad(65);
    height: toPad(25);
    margin-top: -41%;
    margin-left: toPad(100);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(-29.8deg);
  }
  .workship-relation-friends-elliptic-isLargePc {
    position: relative;
    width: toPad(65);
    height: toPad(25);
    margin-top: -41%;
    margin-left: toPad(100);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(-29.8deg);
  }
  .workship-relation-friends-elliptic-en {
    position: relative;
    width: toPad(141);
    height: toPad(22);
    margin-top: -43%;
    margin-left: toPad(70);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(-32.8deg);
  }
  .workship-relation-friends-elliptic-en-isPc {
    position: relative;
    width: toPad(108);
    height: toPad(18);
    margin-top: -45%;
    margin-left: toPad(100);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(-32.8deg);
  }
  .workship-relation-friends-elliptic-en-isLargePc {
    position: relative;
    width: toPad(108);
    height: toPad(18);
    margin-top: -45%;
    margin-left: toPad(100);
    display: flex;
    justify-content: center;
    background: #3e886d;
    border-radius: 23px;
    transform: rotate(-32.8deg);
  }
  .workship-relation-friends-text {
    width: toPad(127);
    height: toPad(25);
    font-size: toPad(16);
    line-height: toPad(25);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-friends-text-isPc {
    width: toPad(50);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-friends-text-isLargePc {
    width: toPad(50);
    height: toPad(25);
    font-size: toPad(12);
    line-height: toPad(27);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-friends-text-en {
    width: toPad(121);
    height: toPad(22);
    font-size: toPad(16);
    line-height: toPad(22);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-friends-text-en-isPc {
    width: toPad(121);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-friends-text-en-isLargePc {
    width: toPad(121);
    height: toPad(16);
    font-size: toPad(12);
    line-height: toPad(18);
    color: #ffffff;
    text-align: center;
  }
  .workship-relation-friends {
    margin-top: toPad(75);
    margin-left: toPad(925);
    width: toPad(130);
    height: toPad(130);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-friends forwards;
  }
  .workship-relation-friends-isPc {
    margin-top: toPad(5);
    margin-left: toPad(865);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-friends-isPc forwards;
  }
  .workship-relation-friends-isLargePc {
    margin-top: toPad(85);
    margin-left: toPad(1015);
    width: toPad(90);
    height: toPad(90);
    position: absolute;
    border: 1px solid #ffffff;
    border-radius: toPad(345);
    animation: 1s slowly-slide-friends-isLargePc forwards;
  }
  .workship-relation-friends-noPower {
    width: toPad(130);
    height: toPad(130);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .workship-relation-friends-noPower-isPc {
    width: toPad(90);
    height: toPad(90);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .workship-relation-friends-noPower-isLargePc {
    width: toPad(90);
    height: toPad(90);
    position: relative;
    border: 1px solid #ffffff;
    border-radius: toPad(435);
    z-index: 2;
    background: linear-gradient(
      269.38deg,
      rgba(166, 218, 199, 0.6) 10.27%,
      rgba(255, 255, 255, 0.6) 87.28%
    );
    backdrop-filter: blur(toPad(4));
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  @keyframes slowly-slide-friends {
    0% {
      margin-top: toPad(264);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(75);
      margin-left: toPad(925);
    }
  }
  @keyframes slowly-slide-friends-isPc {
    0% {
      margin-top: toPad(165);
      margin-left: toPad(532);
    }
    100% {
      margin-top: toPad(5);
      margin-left: toPad(865);
    }
  }
  @keyframes slowly-slide-friends-isLargePc {
    0% {
      margin-top: toPad(245);
      margin-left: toPad(682);
    }
    100% {
      margin-top: toPad(85);
      margin-left: toPad(1015);
    }
  }
  .workship-relation-friends-photos {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(167);
    margin-left: toPad(1020);
    display: inline-flex;
    z-index: 2;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-friends-photos-isPc {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(54);
    margin-left: toPad(938);
    display: inline-flex;
    z-index: 2;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-friends-photos-isLargePc {
    position: absolute;
    width: toPad(131);
    height: toPad(40);
    margin-top: toPad(134);
    margin-left: toPad(1088);
    display: inline-flex;
    z-index: 2;
    animation: 2s opacity-out forwards;
  }
  .workship-relation-friends-content {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(92);
    margin-left: toPad(1070);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-friends-content-isPc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(10);
    margin-left: toPad(970);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-friends-content-isLargePc {
    position: absolute;
    width: auto;
    height: toPad(41);
    margin-top: toPad(90);
    margin-left: toPad(1120);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-footer {
    position: absolute;
    width: fit-content;
    height: toPad(127);
    bottom: toPad(20);
    right: toPad(40);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-footer-isPad {
    position: absolute;
    width: fit-content;
    height: toPad(127);
    bottom: toPad(70);
    right: toPad(40);
    animation: 2s opacity-out forwards;
  }
  .workship-relation-footer-name {
    font-size: toPad(30);
    font-weight: 600;
    line-height: 137.4%;
    text-align: right;
    color: #3e886d;
  }
  .workship-relation-footer-department {
    font-weight: 400;
    font-size: toPad(20);
    line-height: toPad(22);
    text-align: right;
    color: #000000;
  }
  .workship-relation-footer-jobposition {
    font-weight: 400;
    font-size: toPad(20);
    line-height: toPad(22);
    text-align: right;
    color: #000000;
  }
  .workship-relation-footer-updatetime {
    font-weight: 400;
    font-size: toPad(14);
    line-height: toPad(16);
    text-align: right;
    color: #000000;
    margin-top: toPad(21);
  }
  .workship-relation-content {
    background: url("~@/src/assets/common/BG.png") no-repeat center;
    opacity: 0.75;
    background-size: cover;
    height: 92vh;
    padding: toPad(25) toPad(40) toPad(20);
  }
  .workship-relation-content-isPc {
    height: toPad(560) !important;
  }
  .workship-relation-content-isLargePc {
    height: toPad(700) !important;
  }
}
.workship-relation-item {
  display: inline-flex;
  width: 96%;
  margin: toPad(10) 0 toPad(16);

  .item-pic {
    width: toPad(66);
    height: toPad(66);
    border-radius: toPad(40);
    margin-right: toPad(20);
  }

  .item-text {
    width: 77%;
    .text-one {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: toPad(4);
      font-weight: 500;
      font-size: toPad(18);
      .name-text {
        display: inline-block;
        max-width: 80%;
      }
      .lable {
        display: inline-flex;
        color: #fff;
        border-radius: toPad(5) 0px;
        background: linear-gradient(90.61deg, #60cfc6 3.65%, #61bfb4 99.54%);
        padding: 0 toPad(4);
        text-align: center;
        margin-left: toPad(6);
        font-style: normal;
        border: 1px solid rgba(152, 244, 235, 0.6);
        line-height: toPad(16);

        span {
          transform: scale(0.83);
          font-size: toPad(12);
        }
      }
    }

    .text-two {
      margin-bottom: toPad(4);
      .tag {
        display: inline-block;
        padding: 0px toPad(5);
        height: toPad(18);
        line-height: toPad(18);
        margin-right: toPad(4);
        margin-bottom: toPad(4);
        background: rgba(0, 0, 0, 0.05);
        white-space: nowrap;
      }
    }

    .text-thr {
      margin-bottom: toPad(4);
      font-size: toPad(13);
      color: rgba(0, 0, 0, 0.5);

      span {
        margin-left: toPad(16);
        color: #000;
      }
    }
  }
}
.tips {
  display: inline-block;
  width: toPad(15);
  height: toPad(15);
  margin-left: toPad(8);
  cursor: pointer;
}
.tab-block {
  display: flex;
  flex-wrap: wrap;
  //padding: toPad(20) toPad(30) toPad(10);
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .tab-item {
    //height: toPad(48);
    padding: toPad(8) toPad(6);
    border-radius: toPad(2);
    margin-right: toPad(4);
    margin-bottom: toPad(4);
    font-size: toPad(13);
    text-align: center;
    line-height: 1;
    background: #f7f7f7;
    &.active {
      background: rgba(109, 182, 147, 0.29);
    }
  }
}
.sub-wrap {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: auto;
  max-height: 40vh;
  justify-content: space-between;
  .workship-relation-item {
    width: toPad(286);
    height: toPad(66);
    .item-text {
      width: 70%;
      justify-content: center;
      .text-one {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: toPad(8);
        font-weight: 500;
        font-size: toPad(18);
        .name-text {
          display: inline-block;
          max-width: 70%;
        }
      }
    }
  }
  .end-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: toPad(50) auto toPad(50);
    span {
      display: inline-block;
      &.line {
        width: toPad(66);
        height: toPad(1);
        background: rgba(0, 0, 0, 0.5);
      }
      &.txt {
        margin: 0 toPad(20);
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.user-empty {
  margin: toPad(200) auto;
  text-align: center;

  img {
    width: toPad(164);
    height: toPad(142);
  }
}
</style>
<style>
.el-tooltip__popper[x-placement^="left"] .popper__arrow::after {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}

.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}
.el-tooltip__popper[x-placement^="right"] .popper__arrow::after {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}

.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}
.draw_share_atooltip {
  background: #dff2f0 !important;
  color: #000 !important;
}
</style>
